// csync.Views.OptionLevelsField
//
// View model for the options area of the option sets form.
class OptionLevelsField {
  constructor(params) {
    this.params = params;

    // create the draggable list to hold the options
    this.list = new csync.Views.DraggableList({
      items: params.optionLevels || [],
      itemClass: csync.Models.NamedItem,
      wrapper: params.wrapper,
      modal: params.modal,
      optionsLevelsReadOnly: params.optionsLevelsReadOnly,
      multilevel: false,
      canReorder: params.canReorder,
      canRemove: params.canRemove,
      editLink: params.editLink,
      removeLink: params.removeLink,
      modalTitles: {
        new: i18n.t('option_set.new_option_level'),
        edit: i18n.t('option_set.edit_option_level'),
      },
    });

    // No nesting for option levels
    this.list.allowNesting(false);
  }

  // initiates add level process
  add() {
    this.list.new_item(new csync.Models.NamedItem());
  }

  // show or hide the option levels field
  show(yn) {
    const multiOptionField = $('.form-field[data-field-name=option_levels]');
    multiOptionField.css('display', yn ? 'flex' : 'none');
  }
}

csync.Views.OptionLevelsField = OptionLevelsField;
