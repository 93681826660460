// csync.Views.ProjectChangeDropdown

import $ from 'jquery'; // Ensure jQuery is imported
import 'select2';

(function (ns, klass) {
  // constructor
  ns.ProjectChangeDropdown = klass = function (params) {
    const selectElement = $(params.el);

    // Initialize select2
    selectElement.select2();

    // Attach change event listener
    selectElement.on('change', (e) => {
      const new_project_name = $(e.currentTarget).find('option:selected').val();
      if (new_project_name === '') {
        // Redirect straight to root if chose 'None' from dropdown.
        window.location.href = `${csync.AppInit.url_builder.build('/', { mode: 'basic' })}?projectchange=1`;
      } else {
        // If currently in basic mode, we automatically redirect to project root.
        // This is because it doesn't currently make sense to use a path from basic mode in project mode,
        // whereas sharing paths between projects or project<->admin can make sense.
        const path = csync.AppInit.params.mode === 'basic' ? '' : window.location.pathname;

        // Preserve query string and add projectchange param to it.
        const params = new URLSearchParams(window.location.search);
        params.append('projectchange', '1');
        // Remove the 'search' param for search filters.
        params.delete('search');
        const qs = `?${params.toString()}`;

        window.location.href = csync.AppInit.url_builder.build(path, { mode: 'project', project_name: new_project_name }) + qs;
      }
    });
  };
}(csync.Views));
