import Sortable from 'sortablejs';

csync.Views.FormItemsDraggableListView = class FormItemsDraggableListView extends csync.Views.ApplicationView {
  get el() { return '.form-items-list'; }

  initialize(params) {
    this.parent_view = params.parent_view;

    const nestedSortables = [].slice.call(document.querySelectorAll('.item-list'));

    for (let i = 0; i < nestedSortables.length; i++) {
      new Sortable(nestedSortables[i], {
        group: 'nested', // set the same group to allow dragging between lists,
        animation: 150,
        fallbackOnBody: true,
        swapThreshold: 0.65,
        ghostClass: 'sortable-ghost',
        handle: '.form-item-question, .inner', // Drag handle selector within list items
        onEnd: (evt) => {
          this.drop_happened(evt);
        },
        setData: (dataTransfer, item) => {
          dataTransfer.setData('Text', item.textContent); // for compatibility
        },
        onMove: (evt) => {
          return this.drop_target_is_allowed(evt.related, evt.to, evt.dragged);
        }
      });
    }
  }

  drop_target_is_allowed(placeholder, parent, item) {
    let reason = null;

    // Prevent groups from becoming subgroups
    if (item.classList.contains('form-item-group') && parent && parent.classList.contains('form-item-group')) {
      reason = 'group_cannot_be_subgroup';
    }

    // Allow only one level of nesting
    if (item.classList.contains('form-item-group') && parent && parent.closest('.form-item-group')) {
      reason = 'only_one_level_nesting';
    }

    // Additional checks can be performed here if needed
    if (!this.check_condition_order(placeholder, item)) {
      reason = 'invalid_condition';
    }

    // Show the reason if applicable.
    const html = reason ? `<div>${i18n.t(`form.invalid_drop_location.${reason}`)}</div>` : '';
    if (placeholder) {
      //placeholder.innerHTML = html;
    }

    return !reason;
  }

  // Called at the end of a drag. Saves new position.
  drop_happened(evt) {
    const items = document.querySelectorAll('.form-item');
    const updates = [];
  
    items.forEach((item, index) => {
      const { parent_id, rank } = this.get_parent_id_and_rank(item);
      updates.push({
        id: item.dataset.id,
        parent_id,
        rank
      });
    });
  
    // Update all item positions with a single request
    this.parent_view.update_item_positions(updates)
      .then(() => {
        // Optionally, update other components or perform additional actions
        this.parent_view.update_group_action_icons();
        this.update_condition_refs();
      })
      .catch(error => {
        console.error('Error updating item positions:', error);
      });
  }  

  // Gets the parent_id (or null if top-level) and rank of the given li.
  get_parent_id_and_rank(li) {
    const parent = li.parentElement.closest('li.form-item');
    return {
        parent_id: parent ? parent.dataset.id : null,
        rank: Array.from(li.parentElement.children).indexOf(li) + 1,
    };
  }

  // Gets the fully qualified rank, as an array of integers, of the given item/li.
  get_full_rank(li) {
    const path = [];
    let current = li;
    console.log(current);
    while (current && current.tagName.toLowerCase() !== 'body') {
      const index = [...current.parentElement.children].indexOf(current) + 1;
      path.unshift(index);
      current = current.closest('ul').closest('li.form-item, li.placeholder');
    }
    return path;
  }

  // Updates any condition cross-references after a drop or delete.
  update_condition_refs() {
    document.querySelectorAll('.condition').forEach(cond => {
      const refd = document.querySelector(`li.form-item[data-id='${cond.dataset.refId}']`);
      if (refd) {
        cond.querySelector('span').innerHTML = this.get_full_rank(refd).join('.');
      } else {
        cond.remove();
      }
    });
  }

  // Checks if the given position (indicated by placeholder) for the given item, or any of its children, would invalidate any conditions.
  // Returns false if invalid.
  check_condition_order(placeholder, item) {
    // If item has a rule that depends on one more questions, it cannot be moved before any of the referred questions.
    for (const c of item.querySelectorAll('.refd-qing')) {
      const refd = document.querySelector(`li.form-item[data-id='${c.dataset.refId}']`);
      const isSelf = refd && refd.dataset.id === item.dataset.id;

      if (!isSelf && this.compare_ranks(placeholder, refd) !== 1) {
        return false;
      }
    }

    // If item contains a skip rule, it cannot be moved after the skip rule target.
    for (const c of item.querySelectorAll('.skip-rule-link')) {
      const target = document.querySelector(`li.form-item[data-id='${c.dataset.refId}']`);
      const isSelf = target && target.dataset.id === item.dataset.id;

      if (!isSelf && this.compare_ranks(placeholder, target) !== -1) {
        return false;
      }
    }

    // If item's value triggers any skip rules, it cannot be moved after any of the questions whose rules would refer to it.
    const child_ids = [...item.querySelectorAll('.form-item')].map(el => el.dataset.id);
    for (const id of child_ids) {
      for (const refd_qing of document.querySelectorAll(`.refd-qing[data-ref-id='${id}']`)) {
        const referrer = refd_qing.closest('li.form-item');
        const isSelf = referrer && referrer.dataset.id === item.dataset.id;

        if (!isSelf && this.compare_ranks(placeholder, referrer) !== -1) {
          return false;
        }
      }

      // If item is a skip rule target, it cannot be moved before the question that refers to it.
      for (const skip_targets of document.querySelectorAll(`.skip-rule-link[data-ref-id='${id}']`)) {
        const skip_referrer = skip_targets.closest('li.form-item');
        const isSelf = skip_referrer && skip_referrer.dataset.id === item.dataset.id;

        if (!isSelf && this.compare_ranks(placeholder, skip_referrer) !== 1) {
          return false;
        }
      }
    }

    return true;
  }

  // Compares ranks of two items, returning 1 if a > b, 0 if a == b, -1 if a < b
  compare_ranks(a, b) {
    const ar = this.get_full_rank(a);
    const br = this.get_full_rank(b);

    for (let i = 0; i < ar.length; i++) {
      if (ar[i] > br[i]) {
        return 1;
      } else if (ar[i] < br[i]) {
        return -1;
      }
    }

    // If we get to this point, all ranks so far have been equal.
    // If both a and b are same length, we can return 0. Else,
    // the greater rank is the longer one.
    if (ar.length === br.length) {
      return 0;
    } else if (ar.length > br.length) {
      return 1;
    }
    return -1;
  }
};
