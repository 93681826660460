// Defining global objects and constants
window.csync = { Report: {}, Control: {}, Views: {}, Models: {}, Utils: {} };
window.Sassafras = {};
csync.LAT_LNG_REGEXP = /^(-?\d+(\.\d+)?)\s*[,;:\s]\s*(-?\d+(\.\d+)?)/;

// Function to logout
function logout() {
  const logoutButton = document.getElementById('logout_button');
  if (logoutButton) logoutButton.click();
}

// UTILITIES
(function (Utils) {
  // Function to add a name/value pair (e.g. "foo=bar") to a url; checks if there is already a query string
  Utils.add_url_param = function (url, param) {
    const separator = url.includes('?') ? '&' : '?';
    return `${url}${separator}${param}`;
  };
}(window.csync.Utils));

// JQUERY PLUGINS
jQuery.fn.selectText = function () {
  const element = this[0];
  const range = document.createRange();
  range.selectNodeContents(element);
  const selection = window.getSelection();
  selection.removeAllRanges();
  selection.addRange(range);
};

// Polyfills for IE8
if (!Array.prototype.indexOf) {
  Array.prototype.indexOf = function (elt) {
    const len = this.length >>> 0;
    let from = Number(arguments[1]) || 0;
    from = (from < 0) ? Math.ceil(from) : Math.floor(from);
    if (from < 0) from += len;
    for (; from < len; from++) {
      if (this[from] === elt) return from;
    }
    return -1;
  };
}

if (typeof console === 'undefined') {
  window.console = { log: function() {} };
}

// jQuery plugin to prevent double submission of forms
jQuery.fn.preventDoubleSubmission = function () {
  $(this).on('submit', function (e) {
    const $form = $(this);
    if ($form.data('submitted') === true) {
      e.preventDefault();
    } else {
      $form.data('submitted', true);
    }
  });
  return this;
};

// Method to compare two arrays for equality
Array.prototype.equalsArray = function (other) {
  if (this === other) return true;
  if (this == null || other == null) return false;
  if (this.length !== other.length) return false;
  for (let i = 0; i < this.length; ++i) {
    if (this[i] !== other[i]) return false;
  }
  return true;
};
