csync.UrlBuilder = class UrlBuilder {
  constructor(params) {
    this.locale = params.locale;
    this.mode = params.mode;
    this.project_name = params.project_name;
  }

  build(...args) {
    let options = typeof args[args.length - 1] === 'object' ? args.pop() : {};

    options = {
      locale: this.locale,
      mode: this.mode,
      project_name: this.project_name,
      ...options
    };

    const suffix = this.strip_scope(args.join('/'));

    let result;
    switch (options.mode) {
      case 'basic':
        result = `/${options.locale}/${suffix}`;
        break;
      case 'project':
        result = `/${options.locale}/project/${options.project_name}/${suffix}`;
        break;
      case 'admin':
        result = `/${options.locale}/admin/${suffix}`;
        break;
      default:
        throw new Error(`Invalid mode: ${options.mode}`);
    }

    return result.replace(/[/]{2,}/g, '/').replace(/\/$/, '');
  }

  strip_scope(path) {
    const modeProject = this.mode === 'project' ? `project/${this.project_name}` : this.mode;
    const regex = new RegExp(`^/[a-z]{2}/${modeProject}`);

    if (!path || path.match(new RegExp(`^/([a-z]{2}(/${modeProject})?(/)?)?$`))) {
      return '/';
    }

    if (regex.test(path)) {
      return path.replace(regex, '');
    }

    return path;
  }
}
