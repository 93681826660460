import Sortable from "sortablejs";

class DraggableList {
    constructor(attribs) {
        this.listeners = {};
        Object.assign(this, attribs);

        this.removed_items = [];
        this.dirty = false;

        this.enabled = !this.optionsLevelsReadOnly && this.canReorder;

        this.render_items();

        $(this.wrapper).each((index, wrapper) => {
            $(wrapper).on("click", (event) => {
                if ($(event.target).is("a.action-link-edit")) {
                    this.edit_item($(event.target).closest("div.inner").data("item"));
                    event.preventDefault();
                } else if ($(event.target).is("a.action-link-remove")) {
                    this.remove_item($(event.target).closest("div.inner").data("item"));
                    event.preventDefault();
                }
            });
        });

        $(this.modal).each((index, modal) => {
            const primaryButton = $(modal).find("button.btn-primary, button[data-action]");
            if (primaryButton.length) {
                primaryButton.on("click", (event) => {
                    const action = $(event.target).data("action") || "close";
                    this.saveItem(action);
                    event.preventDefault();
                });
            } else {
                console.error("Primary button not found in modal:", modal);
            }

            const secondaryButton = $(modal).find("button.btn-secondary");
            if (secondaryButton.length) {
                secondaryButton.on("click", (event) => {
                    event.stopPropagation();
                    new bootstrap.Modal(modal).hide();
                    this.cancel_edit();
                });
            } else {
                console.error("Secondary button not found in modal:", modal);
            }

            $(modal).on("keypress", (event) => {
                if (event.key === "Enter") {
                    event.preventDefault();
                    const btn = $(modal).find(".btn-primary:last-of-type");
                    if (btn.length && window.getComputedStyle(btn[0]).display !== "none") {
                        btn.click();
                    }
                }
            });

            $(modal).on("keydown", (event) => {
                if (event.key === "Escape") {
                    event.stopPropagation();
                    new bootstrap.Modal(modal).hide();
                }
            });
        });

        $("body").on("keyup change", this.toggle_save_button.bind(this));
    }

    validate_modal() {
        let valid = true;

        valid &= $(this.modal)
            .find("div.translation input")
            .toArray()
            .some((item) => $(item).val().trim() !== "");

        if (this.allow_coordinates) {
            const latitude = $(this.modal).find("div.coordinate input[data-field=latitude]").val().trim();
            const longitude = $(this.modal).find("div.coordinate input[data-field=longitude]").val().trim();

            if (latitude !== "" || longitude !== "") {
                if (latitude === "" || longitude === "") {
                    valid = false;
                }

                if (latitude !== "") {
                    if (Number(latitude) > 90 || Number(latitude) < -90 || !Number(latitude)) {
                        valid = false;
                    }
                }

                if (longitude !== "") {
                    if (Number(longitude) > 180 || Number(longitude) < -180 || !Number(longitude)) {
                        valid = false;
                    }
                }
            }
        }

        return valid;
    }

    toggle_save_button() {
        const show = this.validate_modal();

        if (this.modalMode === "new") {
            $(this.modal).find(".buttons-default").hide();
            $(this.modal).find(".buttons-new").toggle(show);
        } else {
            $(this.modal).find(".buttons-new").hide();
            $(this.modal).find(".buttons-default").toggle(show);
        }
    }

    allowNesting(yn) {
        const self = this;

        // maxLevels == 0 means no limit
        if (self.enabled) {
            // Destroy any previous Sortable instances on self.ol
            if (self.ol && self.ol.sortableInstance) {
                self.ol.sortableInstance.destroy();
            }

            if (self.ol instanceof HTMLElement) {
                self.ol.sortableInstance = Sortable.create(self.ol, {
                    handle: "div",
                    draggable: "li",
                    ghostClass: "placeholder",
                    forceFallback: true,
                    animation: 150,
                    group: {
                        name: "nested",
                        pull: true,
                        put: true,
                    },
                    fallbackOnBody: true,
                    swapThreshold: 0.65,
                    onEnd: function (evt) {
                        // Handle nesting here if needed
                        updateNestingLevels(evt.to);
                    },
                });
            } else {
                console.error("self.ol is not a valid HTMLElement:", self.ol);
            }

            // Function to update nesting levels
            function updateNestingLevels(container) {
                let maxLevels = yn ? 0 : 1;
                let currentLevel = 1;

                function processItems(items, level) {
                    items.forEach((item) => {
                        if (level > maxLevels && maxLevels !== 0) {
                            // Move item back to its original level or handle it accordingly
                        }
                        let nestedContainer = $(item).find("ol")[0];
                        if (nestedContainer) {
                            processItems([...nestedContainer.children], level + 1);
                        }
                    });
                }

                processItems([...container.children], currentLevel);
            }
        }
    }

    render_items() {
        this.ol = this.render_item({ root: true, children: this.items });
        // append to wrapper div
        if (this.ol instanceof HTMLElement) {
            $(this.wrapper).append(this.ol);
        } else {
            console.error("Rendered items did not return a valid HTMLElement:", this.ol);
        }

        if (this.enabled && this.ol instanceof HTMLElement) {
            this.sortable = new Sortable(this.ol, {
                handle: "div",
                animation: 150,
                group: {
                    name: "nested",
                    pull: true,
                    put: true,
                },
                fallbackOnBody: true,
                swapThreshold: 0.65,
                onEnd: (evt) => {
                    this.items.dirty = true;
                    this.trigger("change");
                },
                onMove: (evt) => {
                    return this.parentChangeAllowed ? this.parentChangeAllowed(evt.dragged, evt.to, evt.related) : true;
                },
            });
        } else {
            console.warn("Sortable initialization failed: ol is not a valid HTMLElement or sorting is disabled 🙁.");
        }
    }

    // renders an li tag containing the inner tag plus an ol tag if there are children
    // if item.root = true, returns just the ol
    // ol may be undefined if there are no children
    render_item(item) {
        const self = this;

        // wrap the item in an object (unless it's root)
        if (!item.root) item = new self.itemClass(item);

        const li = $("<li>");

        // render inner
        if (!item.root) li.append(self.render_inner(item));

        // recurse and render children
        let ol;
        if (item.children) {
            ol = $("<ol>");
            if (item.children.length > 0) $(self.wrapper).show();
            item.children.forEach((c) => {
                ol.append(self.render_item(c));
            });
        }
        li.append(ol);

        return item.root ? ol[0] : li[0]; // Ensure the returned element is an HTMLElement
    }

    // builds the inner div tag for an item
    render_inner(item) {
        const self = this;

        // make inner tag
        const inner = $("<div>").attr("class", "inner");

        // wrap the item in an object if not already wrapped
        if (!item.translation) item = new self.itemClass(item);

        // add sort icon if not in show mode
        if (self.enabled) {
            inner.append(
                $(`<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sort-up" viewBox="0 0 16 16">
        <path d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.5.5 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5M7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1z"/>
      </svg>`)
            );
        }

        // add name (add nbsp to make sure div doesn't collapse if name is blank)
        const text = item.translation();
        if (text === "") {
            inner.append("&nbsp;");
        } else {
            const el = $("<span />").text(text);
            inner.append(el);
        }

        if (item.value || item.value === 0) {
            const value = $("<span>").addClass("value").text(` (${item.value})`);
            inner.append(value);
        }

        // add edit/remove unless in show mode
        if (!self.optionsLevelsReadOnly) {
            const links = $("<div>").attr("class", "links");

            // only show the edit link if the item is editable
            if (item.editable) links.append(self.edit_link);

            // don't show the removable link if the item isn't removable
            // or if the global removable permission is false
            if (self.canRemove && item.removable) links.append(self.removeLink);

            // add a spacer if empty, else it won't render right
            if (links.is(":empty")) links.append("&nbsp;");

            links.appendTo(inner);
        }

        // add locales
        inner.append($("<em>").html(item.locale_str()));

        // associate item with data model bidirectionally
        inner.data("item", item);
        item.div = inner;

        return inner;
    }

    saveItem(action) {
        const data = {};
        $(this.modal)
            .find("input")
            .each((index, el) => {
                const field = $(el).data("field");
                data[field] = el.type === "checkbox" ? el.checked : $(el).val();
            });

        const target = $(this.modal).data("item");
        const old_item = target.toJSON ? target.toJSON() : { ...target };

        if (target) target.update(data);

        new bootstrap.Modal(this.modal).hide();
        this.dirty = true;

        this.trigger("edit", old_item, target, action);
    }

    edit_item(item) {
        $(this.modal).data("item", item);
        this.modalMode = "edit";

        $(this.modal)
            .find("div.translation input")
            .each((index, el) => {
                const field = $(el).data("field");
                $(el).val(item[field]);
            });

        $(this.modal)
            .find("div.coordinate input")
            .each((index, el) => {
                const field = $(el).data("field");
                $(el).val(item[field]);
            });

        new bootstrap.Modal(this.modal).show();
        this.toggle_save_button();
    }

    cancel_edit() {
        $(this.modal)
            .find("input")
            .each((index, el) => {
                $(el).val("");
            });
    }

    remove_item(item) {
        this.trigger("remove", item);

        const target = $(this.wrapper)
            .find(".inner")
            .filter((index, el) => $(el).data("item") === item)
            .closest("li");
        target.remove();

        this.removed_items.push(item);
        this.dirty = true;
    }

    new_item() {
        this.modalMode = "new";
        $(this.modal).data("item", null);

        $(this.modal)
            .find("div.translation input")
            .each((index, el) => {
                $(el).val("");
            });

        $(this.modal)
            .find("div.coordinate input")
            .each((index, el) => {
                $(el).val("");
            });

        new bootstrap.Modal(this.modal).show();
        this.toggle_save_button();
    }

    add_event_listener(event, callback) {
        if (!this.listeners[event]) {
            this.listeners[event] = [];
        }
        this.listeners[event].push(callback);
    }

    // returns number of items
    count() {
        return $(this.ol).find("li").length;
    }

    // registers event listeners
    on(event_name, cb) {
        if (!this.listeners[event_name]) this.listeners[event_name] = [];
        this.listeners[event_name].push(cb);
    }

    trigger(event, ...args) {
        if (this.listeners[event]) {
            this.listeners[event].forEach((callback) => callback(...args));
        }
    }

    // returns a tree of items in the form:
    // [
    //   {item: {...}, children: [
    //     {item: {...}, children: [
    //       {item: {...}},
    //       {item: {...}},
    //       {item: {...}}
    //     ]},
    //     {item: {...}, children: [
    //       {item: {...}},
    //       {item: {...}}
    //     ]}
    //   ]}
    // ]
    itemTree() {
        return this.ol_to_tree($(this.ol));
    }

    ol_to_tree(ol) {
        return ol
            .children("li")
            .map((index, el) => {
                const sub_ol = $(el).children("ol").first();
                return {
                    item: $(el).children("div").data("item"),
                    children: sub_ol.length ? this.ol_to_tree(sub_ol) : null,
                };
            })
            .get();
    }

    // gets the number of top-level items in the list presently
    size() {
        return $(this.ol).children("li").length;
    }

    // gets the maximum depth of any item in the list
    maxDepth() {
        let max = 0;
        while ($(this.ol).find("li ".repeat(max + 1)).length) max++;
        return max;
    }

    // checks to see if there is an item matching the given one
    has_duplicate_of(item) {
        return this.has_with_name(item.translation());
    }

    // checks if there is an item with the given name
    has_with_name(name) {
        let found = false;
        $(this.ol)
            .find("div.inner")
            .each((index, el) => {
                if ($(el).data("item").translation() == name) {
                    found = true;
                    return false;
                }
            });
        return found;
    }
}

class Item {
    constructor(data) {
        this.editable = true;
        this.removable = true;
        Object.assign(this, data);
    }

    translation() {
        return this.name || "";
    }

    update(data) {
        Object.assign(this, data);
    }

    toJSON() {
        return Object.fromEntries(Object.entries(this).filter(([key, value]) => typeof value !== "function"));
    }
}

csync.Views.DraggableList = DraggableList;
csync.Views.Item = Item;
