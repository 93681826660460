// csync.LocationPicker
(function (ns, klass) {
  // constructor
  ns.LocationPicker = klass = function (el) {
    const self = this;
    self.el = el;
    self.el.on('shown.bs.modal', (e) => { self.initialize_map(); });
    self.map_ready = false;
  };

  klass.prototype.show = function (field) {
    const self = this;
    new bootstrap.Modal(self.el, {}).show();
    self.location_field = field;
  
    if (self.location_field.val()) self.set_location(self.parse_lat_lng(self.location_field.val()));
  
    if (self.map_ready) self.mark_location({ pan: true });
  };  

  // Fires only on first show.
  klass.prototype.initialize_map = function () {
    const self = this;
    if (!self.location) self.set_location([-34.397, 150.644]); // Default center

    const style = {
      "version": 8,
      "sources": {
        "osm": {
          "type": "raster",
          "tiles": ["https://a.tile.openstreetmap.org/{z}/{x}/{y}.png"],
          "tileSize": 256,
          "attribution": "&copy; OpenStreetMap Contributors",
          "maxzoom": 19
        }
      },
      "layers": [
        {
          "id": "osm",
          "type": "raster",
          "source": "osm"
        }
      ]
    };

    self.map = new maplibregl.Map({
      container: "map",
      style: style,
      center: [self.location[1], self.location[0]],
      zoom: self.location ? 7 : 2
    });

    self.mark_location();
    self.search_focus(false);

    // Hook up events.
    self.map.on('click', (event) => { self.map_click(event); });
    self.el.find('button.btn-primary').click(() => { self.close(true); return false; });
    self.el.find('form.location-search input.query').focus(() => { self.search_focus(true); });
    self.el.find('form.location-search input.query').blur(() => { self.search_focus(false); });
    self.el.find('form.location-search').submit(() => { self.search_submit(); return false; });

    self.map_ready = true;
  };

  // get float values for lat ang lng from a string
  klass.prototype.parse_lat_lng = function (str) {
    const m = str.match(csync.LAT_LNG_REGEXP);
    return m && m[1] && m[3] ? [parseFloat(m[1]), parseFloat(m[3])] : null;
  };

  // handles a click event on the map
  klass.prototype.map_click = function (event) {
    const self = this;
    self.set_location([event.lngLat.lat, event.lngLat.lng]);
    self.mark_location();
  };

  klass.prototype.search_focus = function (is_focus) {
    const self = this;
    // get ref.
    const box = self.el.find('form.location-search input.query');
    const init_str = i18n.t('location_picker.search_locations');

    // if focused, blank the box and set the color and font style
    if (is_focus && box.val() == init_str) box.css('color', '#222').css('font-style', 'normal').val('');

    // otherwise (blur), reset the box to the blank style using the boilerplate code
    else if (box.val().trim() == '') box.css('color', '#888').css('font-style', 'italic').val(init_str);
  };

  // submits the search to the MapLibre geocoder class
  klass.prototype.search_submit = function () {
    const self = this;

    // get query
    const query = self.el.find('form.location-search input.query').val().trim();

    // do nothing if empty
    if (query == '') return;

    csync.AppInit.loading(true);

    // Geocoding using MapLibre Geocoder
    maplibregl.geocoder.forwardGeocode({ query: query })
      .send()
      .then(function (response) {
        csync.AppInit.loading(false);
        const results_div = self.el.find('form.location-search div.results').empty();
        if (response && response.body && response.body.features) {
          // create links
          response.body.features.forEach(function (feature) {
            results_div.append($('<a>')
              .attr('href', '#').addClass('result-link')
              .attr('title', `${feature.center[1]}, ${feature.center[0]}`)
              .text(feature.place_name));
          });

          self.el.find('a.result-link').click((e) => {
            e.preventDefault();
            self.set_location(self.parse_lat_lng(e.target.title));
            self.mark_location({ pan: true });
          });
        } else {
          alert('Search Error');
        }
      })
      .catch(function (error) {
        console.error('Error:', error);
        csync.AppInit.loading(false);
        alert('Search Error');
      });
  };

  klass.prototype.set_location = function (val) {
    const self = this;
    self.location = [val[1].toFixed(6), val[0].toFixed(6)];
  };

  // updates the map to indicate that the current location has been chosen
  klass.prototype.mark_location = function (options) {
    const self = this;
    csync.AppInit.loading(false);

    if (self.location) {
      if (!self.marker) {
        self.marker = new maplibregl.Marker().setLngLat([self.location[1], self.location[0]]).addTo(self.map);
      } else {
        // move the marker
        self.marker.setLngLat([self.location[1], self.location[0]]);
      }
    }

    // show the chosen location
    self.el.find('.cur-lat-lng').html(`${i18n.t('location_picker.current_location')}: ${
      self.location ? `${self.location[0]} ${self.location[1]}` : 'None'}`);

    // pan if requested
    if (options && options.pan) self.map.panTo(self.marker.getLngLat());
  };

  // closes the window, optionally saving the chosen location in the location field
  klass.prototype.close = function (save) {
    // copy the value if requested
    if (save) this.location_field.val(`${this.location[0]} ${this.location[1]}`);
  
    new bootstrap.Modal(this.el).hide();
  };  
}(csync));