// THIS VIEW IS DEPRECATED, PREFER THE NEWER ONE QuestionFormView
//
// csync.Views.QuestionForm
//
// View model for Question form
class QuestionForm {
  constructor(params) {
    this.params = params;

    // Hookup type change event and trigger immediately
    const typeBox = $('div.question_fields .form-field#qtype_name select.form-select');
    typeBox.on('change', this.questionTypeChanged.bind(this));

    // Trigger the type box changed event immediately
    this.questionTypeChanged({ initial: true });

    // Hookup add option set link
    $('div.question_fields a.create_option_set').on('click', this.showOptionSetForm.bind(this));

    // Hookup form submit for modal
    $('#create-option-set .btn-primary').on('click', () => {
      $('form.option_set_form').submit();
    });

    // Register a callback for when option set form submission is done
    $(document).on('option_set_form_submit_success', 'form.option_set_form', (e, optionSet) => {
      this.optionSetCreated(optionSet);
    });
  }

  // Gets the current value of the field with the given name
  fieldValue(fieldName) {
    const fieldDiv = $(`.question_fields .form-field[data-field-name='${fieldName}']`);

    if (!fieldDiv.length) {
      console.error(`Field div not found for field name: ${fieldName}`);
      return null;
    }

    if (fieldDiv.children('.read-only').length > 0) {
      const wrapper = fieldDiv.find('.ro-val');
      return wrapper.data('val') || wrapper.text();
    }

    const inputElement = fieldDiv.find('input, select, textarea');

    if (!inputElement.length) {
      console.error(`No input, select, or textarea found in field div: ${fieldName}`);
      return null;
    }

    return inputElement.val();
  }

  questionTypeChanged(options = {}) {
    const selectedType = this.fieldValue('qtype_name');

    const showMaxMin = ['decimal', 'integer'].includes(selectedType);
    $('.question_fields .minmax .form-field').css('display', showMaxMin ? 'flex' : 'none');

    if (!showMaxMin) {
      $(".form-field#minimum input[id$='_minimum']").val('');
      $(".form-field#minimum input[id$='_minstrictly']").prop('checked', false);
      $(".form-field#maximum input[id$='_maximum']").val('');
      $(".form-field#maximum input[id$='_maxstrictly']").prop('checked', false);
    }

    const hideKeyQ = ['image', 'annotated_image', 'signature', 'sketch', 'audio', 'video'].includes(selectedType);
    $('.question_fields .question_key').css('display', hideKeyQ ? 'none' : 'flex');

    if (hideKeyQ) {
      $(".form-field#key input[id$='_key']").val('');
      $(".form-field#key input[id$='_key']").prop('checked', false);
    }

    options.multilevel = selectedType === 'select_one';
    this.showOptionSetSelect(['select_one', 'select_multiple'].includes(selectedType), options);
  }

  showOptionSetSelect(show, options = {}) {
    const select = $('div.question_fields .form-field[data-field-name=option_set_id]');
    select.css('display', show ? 'flex' : 'none');

    if (show) {
      const multOptions = select.find('.form-control select option[data-multilevel=true]');
      multOptions.prop('disabled', !options.multilevel);
    }

    if (!options.initial) {
      select.find('.form-control select').val('');
    }
  }

  showOptionSetForm() {
    csync.AppInit.loading(true);

    const questionTypeParam = `?adding_to_question_type=${this.fieldValue('qtype_name')}`;
    const loadUrl = this.params.new_option_set_path + questionTypeParam;

    const questionIdentifier = this.getQuestionIdentifier();
    const modalHeader = questionIdentifier
      ? 'option_set.create_for_question'
      : 'option_set.create_for_new_question';

    $('#create-option-set .modal-title').text(i18n.t(modalHeader, { identifier: questionIdentifier }));

    $('#create-option-set .modal-body.option-set').load(loadUrl, () => {
      new bootstrap.Modal($('#create-option-set')).show();
      csync.AppInit.loading(false);
    });
  }

  getQuestionIdentifier() {
    const attribute = 'code';
    let questionIdentifier = $(`#question_${attribute}`).val() ||
      $(`#questioning_question_attributes_${attribute}`).val();
    return questionIdentifier?.trim();
  }

  optionSetCreated(optionSet) {
    new bootstrap.Modal($('#create-option-set')).hide();

    const option = $('<option>', { value: optionSet.id, 'data-multilevel': optionSet.multilevel }).text(optionSet.name);
    const selectField = $('div.question_fields .form-field#option_set_id select');
    selectField.append(option).val(optionSet.id);

    const optionSetField = $('div.question_fields .form-field#option_set_id');
    optionSetField.addClass('highlight');

    setTimeout(() => {
      optionSetField.removeClass('highlight');
    }, 2000);
  }
}

csync.Views.QuestionForm = QuestionForm;

