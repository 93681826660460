// csync.Report.BarChartDisplay < csync.Report.Display
(function (ns, klass) {
  // constructor
  ns.BarChartDisplay = klass = function (report) {
    this.report = report;
  };

  const format_header = function (str) {
    return (str || '[Null]').strip_html();
  };

  // inherit
  klass.prototype = new ns.Display();
  klass.prototype.constructor = klass;
  klass.prototype.parent = ns.Display.prototype;

  klass.prototype.render = function () {
    this.finish_render();
  }

  klass.prototype.finish_render = function () {
    const _this = this;
    const { data } = this.report.attribs;
    const { headers } = this.report.attribs;

    // set up data
    const categories = headers.row.cells.map(cell => format_header(cell.name));
    const series = headers.col.cells.map((cell, idx) => {
      return {
        name: format_header(cell.name),
        data: data.rows.map(row => row[idx] || 0)
      };
    });

    // get whether it's stacked
    const stacked = this.report.attribs.bar_style == 'stacked';

    const options = {
      chart: {
        type: 'bar',
        height: '500px',
        stacked: stacked,
        toolbar: {
          show: true
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          //barHeight: '80%'
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: categories,
        title: {
          text: this.report.aggregation()
        }
      },
      yaxis: {
        title: {
          text: headers.row.title
        }
      },
      series: series,
      themem: 'palette1', 
      legend: {
        position: 'bottom'
      }
    };

    const chart = new ApexCharts(document.querySelector('.report-body'), options);
    chart.render();
  };
}(csync.Report));

