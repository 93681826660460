// csync.Views.DashboardMapView
//
// View model for the dashboard map
csync.Views.DashboardMapView = class DashboardMapView extends csync.Views.ApplicationView {
  // constructor
  initialize(params) {
    this.params = params;
    this.disabled = params.offline;
    if (this.disabled) {
      this.show_disabled_notice();
    } else {
      this.setup_map();
      this.add_controllers();
    }
  }

  show_disabled_notice() {
    $('.map-canvas').remove();
    if (this.params.offline) {
      $('.response-locations-offline').show();
    } else if (!this.params.key_present) {
      $('.response-locations-no-key').show();
    }
  }

  setup_map() {
    const style = {
      "version": 8,
      "sources": {
        "osm": {
          "type": "raster",
          "tiles": ["https://a.tile.openstreetmap.org/{z}/{x}/{y}.png"],
          "tileSize": 256,
          "attribution": "&copy; OpenStreetMap Contributors",
          "maxzoom": 19
        }
      },
      "layers": [
        {
          "id": "osm",
          "type": "raster",
          "source": "osm"
        }
      ]
    };

    this.map = new maplibregl.Map({
      container: 'map',
      style: style,
      center: [0, 0],
      zoom: 3,
      bearing: 0, // Default bearing
      pitch: 0, // Default pitch
      antialias: true // Smooth edges
    });

    // keep track of which response ids we've rendered
    this.distinct_answers = {};

    // add the markers and keep expanding the bounding rectangle
    const bounds = new maplibregl.LngLatBounds();
    for (const l of Array.from(this.params.locations)) {
      const m = this.add_answer(l);
      if (m) {
        bounds.extend(m.getLngLat());
      }
    }

    // if there are stored bounds, use those to center map
    if (this.load_bounds(this.params.serialization_key)) {
      true; // do nothing since the method call does it all

      // else if there are no responses, just center at 0 0
    } else if (this.params.locations.length === 0) {
      this.map.setCenter([0, 0]);

      // else use bounds determined above
    } else {
      // center/zoom the map
      // Calculate the average center point of all coordinates
      const centerLng = (bounds.getEast() + bounds.getWest()) / 2;
      const centerLat = (bounds.getNorth() + bounds.getSouth()) / 2;
      const center = [centerLng, centerLat];

      this.map.setCenter(center);
      this.map.fitBounds(bounds, { padding: 20 });
    }

    // save map bounds each time they change
    this.map.on('moveend', () => this.save_bounds(this.params.serialization_key));
  }

  add_answer(answer) {
    const [response_id, latitude, longitude] = Array.from(answer);

    // only add each response_id/lat/long once
    if (this.distinct_answers[answer]) { return; }

    // get float values from string
    const lat = parseFloat(latitude);
    const lng = parseFloat(longitude);

    // create marker
    const m = new maplibregl.Marker({
      color: '#d00'
    })
      .setLngLat([lng, lat])
      .setPopup(new maplibregl.Popup().setHTML(`<div class="info-window">${i18n.t('activerecord.models.response.one')} #${response_id}</div>`))
      .addTo(this.map);

    // keep track of the response_id/lat/long
    this.distinct_answers[answer] = true;

    m.getElement().addEventListener('click', () => this.show_info_window(response_id));

    return m;
  }

  show_info_window(r_id) {
    // do the ajax call immediately, don't wait for the info window to open
    $.ajax({
      url: this.params.info_window_url,
      method: 'get',
      data: { response_id: r_id },
      beforeSend: function () {
        // Show loading message before sending the request
        $('.info-window').html(`<h3>${i18n.t('response.loading')}</h3>`);
      },
      success(data) {
        $('.info-window').html(data);
      },
      error() {
        if (csync.unloading) return;
        $('.info-window').html(i18n.t('layout.server_contact_error'));
      },
    });
  }

  add_controllers() {
    // Add Geolocation Control
    const geolocateControl = new maplibregl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: true
    });
    this.map.addControl(geolocateControl);

    // Add Scale Control
    const scaleControl = new maplibregl.ScaleControl({
      maxWidth: 80,
      unit: 'metric' // Use 'imperial' for imperial units
    });
    this.map.addControl(scaleControl);

    // Add Navigation Control
    const navigationControl = new maplibregl.NavigationControl();
    this.map.addControl(navigationControl, 'top-left');

/*     // Add Layer Control (Assuming you have multiple layers)
    const layerControl = new maplibregl.LayerControl();
    this.map.addControl(layerControl);

    // Add Export Control
    const exportControl = new maplibregl.ExportControl({
      formats: ['png', 'jpg', 'pdf'],
      exportIcon: 'data:image/png;base64,<<base64-encoded-icon>>', // Custom export icon
      exportTitle: 'Export Map' // Tooltip title
    });
    this.map.addControl(exportControl, 'top-right');*/
  } 

  // stores the current map bounds in localStorage using the given key
  save_bounds(key) {
    // add hash with center and zoom
    const bounds = {
      center: this.map.getCenter().toArray(),
      zoom: this.map.getZoom(),
    };

    // write out again
    window.localStorage.dashboardMapBounds = JSON.stringify({ [key]: bounds });
  }

  // attempts to load the map bounds from localStorage using the given key
  // if successful, returns true
  // if not found, does nothing and returns false
  load_bounds(key) {
    const saved_bounds = JSON.parse(window.localStorage.dashboardMapBounds || '{}');
    const bounds = saved_bounds[key];
    if (bounds) {
      this.map.setCenter(bounds.center);
      this.map.setZoom(bounds.zoom);
      return true;
    }
    return false;
  }

  update_map(data) {
    if (this.disabled) { return; }
    return Array.from(data).map((answer) => this.add_answer(answer));
  }

  center() {
    if (this.disabled) { return null; }
    return this.map.getCenter();
  }

  // Called after viewport is resized. If center is given, sets the new center for the map.
  resized(center) {
    if (this.disabled) { return; }
    if (center) { this.map.setCenter(center); }
  }
};
