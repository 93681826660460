// Models the batch actions done on index pages
import _ from "underscore";
import { TurboConfirm } from "@rolemodel/turbo-confirm";

const tc = new TurboConfirm({
    activeClass: "show"
});

class BatchActionsView extends csync.Views.ApplicationView {
    get el() {
        return ".csync-table-responsive";
    }

    initialize(params, search_form_view) {
        // Set up instance variables
        this.action_links = $(".index-links");
        this.form =
            this.$el.find("form.index-table-form").length > 0
                ? this.$el.find("form.index-table-form")
                : this.$el.closest("form.index-table-form");
        this.select_all_pages_field = this.$el.find("input[name=select_all_pages]");
        this.alert = this.$el.find("div.alert");
        this.class_name = i18n.t(`activerecord.models.${params.class_name}.many`);
        this.search_form_view = search_form_view;
        this.entries = this.$el.data("entries");
        this.items = this.$el.data("items");
        this.pages = this.$el.data("pages");

        // Flash the modified object if given
        if (params.modified_obj_id) {
            const selector = `#${params.class_name}_${params.modified_obj_id}`;
            const $element = $(selector);

            $element.addClass("highlight");

            // Remove the highlight class after the duration (2000ms)
            setTimeout(() => {
                $element.removeClass("highlight");
            }, 2000);
        }

        // Bind events to the document level
        this.bindEvents();

        if (params.batch_ops) {
            return this.update_links();
        }
    }

    bindEvents() {
        // Bind events to the document level for dynamic elements
        $(document).on("click", "#select-all-link", this.select_all_clicked.bind(this));
        $(document).on("click", "a.select_all_pages", this.select_all_pages_clicked.bind(this));
        $(document).on("click", "a.deselect_all_pages", this.deselect_all_pages_clicked.bind(this));
        $(document).on("click", "a.batch-submit-link", this.submit_batch.bind(this));
        $(document).on("change", "input[type=checkbox].batch_op", this.checkbox_changed.bind(this));
    }

    // selects/deselects all boxes on page
    select_all_clicked(event) {
        event.preventDefault();
        this.toggle_all_boxes(!this.all_checked());
        this.updateSelectAllPagesFieldValue();
        return this.update_links();
    }

    select_all_pages_clicked(event) {
        event.preventDefault();
        this.toggle_all_boxes(true);
        this.select_all_pages_field.val("1");
        return this.update_links();
    }

    checkbox_changed(event) {
        this.updateSelectAllPagesFieldValue();
        return this.update_links();
    }

    deselect_all_pages_clicked(event) {
        event.preventDefault();
        this.toggle_all_boxes(false);
        this.select_all_pages_field.val("");
        return this.update_links();
    }

    reset_alert() {
        return this.alert
            .stop()
            .hide()
            .removeClass("alert-danger alert-info alert-warning alert-success")
            .removeAttr("opacity");
    }

    // tests if some but not all boxes are checked
    some_checked() {
        const cbs = this.get_batch_checkboxes();
        const checked = _.filter(cbs, (cb) => cb.checked);
        return checked.length > 0 && checked.length < cbs.length;
    }

    // Updates the select all link and the select all pages notice.
    update_links() {
        let label;
        let inputType;

        switch (true) {
            case this.all_checked():
                label = "select_all";
                inputType = "checkbox";
                break;
            case this.some_checked():
                label = "some_select";
                inputType = "checkbox";
                break;
            default:
                label = "deselect_all";
                inputType = "checkbox";
                break;
        }

        const inputElement = document.createElement("input");
        inputElement.setAttribute("type", inputType);
        inputElement.checked = this.all_checked();
        inputElement.indeterminate = this.some_checked();

        // Replace the existing SVG with the input element
        const selectAllLink = document.getElementById("select-all-link");
        selectAllLink.innerHTML = "";
        selectAllLink.appendChild(inputElement);
        selectAllLink.setAttribute("title", i18n.t(`layout.${label}`));

        // Reset alert and other functionality as needed
        this.reset_alert();

        if (this.select_all_pages_field.val() && this.get_selected_count() >= this.items) {
            const msg = `${i18n.t("index_table.messages.selected_all_rows", {
                class_name: this.class_name.toLowerCase(),
                count: this.entries > this.items ? this.items : this.entries,
            })} <a href='#' class='deselect_all_pages'>${i18n.t("layout.deselect_all")}</a>`;
            this.alert.html(msg).addClass("alert-info").show();
        } else if (this.pages >= 1 && this.get_selected_count() > 0) {
            const selectedCount = this.get_selected_count();
            const totalCount = this.entries > this.items ? this.items : this.entries;

            const selectAllRowsLink = selectedCount !== this.entries
                ? `<a href='#' class='select_all_pages'>${i18n.t("index_table.messages.select_all_rows", {
                    class_name: this.class_name.toLowerCase(),
                    count: totalCount,
                })}</a>`
                : `<a href='#' class='deselect_all_pages'>${i18n.t("layout.deselect_all")}</a>`;

            const msg =
                selectedCount === 1
                    ? `${i18n.t("index_table.messages.select_one_row", {
                        class_name: this.class_name.toLowerCase(),
                        count: selectedCount,
                    })}`
                    : `${i18n.t("index_table.messages.selected_rows_page", {
                        class_name: this.class_name.toLowerCase(),
                        count: selectedCount,
                    })}${selectAllRowsLink}`;
            this.alert.html(msg).addClass("alert-info").show();
        }

        // Enable/disable batch links based on selection
        this.update_batch_links();
    }

    update_batch_links() {
        const selectedCount = this.get_selected_count();
        const batchLinks = this.action_links.find("button#batch-actions-button");

        if (selectedCount > 0) {
            batchLinks.prop("disabled", false).removeAttr("aria-disabled");
        } else {
            batchLinks.prop("disabled", true).attr("aria-disabled", "true");
        }
    }

    // gets all checkboxes in batch_form
    get_batch_checkboxes() {
        return this.form.find("input[type=checkbox].batch_op");
    }

    get_selected_count() {
        if (this.select_all_pages_field.val()) {
            return this.entries;
        }
        return _.size(_.filter(this.get_batch_checkboxes(), (cb) => cb.checked));
    }

    get_selected_items() {
        return this.form.find("input.batch_op:checked");
    }

    toggle_all_boxes(bool) {
        const cbs = this.get_batch_checkboxes();
        return Array.from(cbs).map((cb) => (cb.checked = bool));
    }

    // tests if all boxes are checked
    all_checked() {
        const cbs = this.get_batch_checkboxes();
        return _.every(cbs, (cb) => cb.checked);
    }

    // If all checkboxes are checked and there is only one page,
    // set the "select all pages" to true; otherwise, set it to false.
    updateSelectAllPagesFieldValue() {
        return this.select_all_pages_field.val(this.all_checked() ? "1" : "");
    }

    submit_batch(event) {
        event.preventDefault();
        $("#confirm").show();

        const options = $(event.target).data();
        const selected = this.get_selected_count();

        if (selected === 0) {
            this.alert.html(i18n.t("layout.no_selection"))
                .addClass("alert-danger")
                .show()
                .delay(2500)
                .fadeOut("slow", this.reset_alert.bind(this));
            return false;
        }

        const confirmTitle = options.turboConfirm ? i18n.t(`${options.turboConfirm}.${selected === 1 ? 'one' : 'others'}`) : null;
        const confirmDetails = options.confirmDetails ? i18n.t(options.confirmDetails, { count: selected }) : null;
        const confirmButton = options.confirmButton ? i18n.t(options.confirmButton) : i18n.t("common.delete");

        const proceed = () => {
            // construct a temporary form
            //
            // TODO: This fake DOM submission logic is old, hacky, and should be refactored eventually.
            //   Perhaps it could be a simple AJAX request which updates the paginated list on success.
            const form = $('<form>').attr('action', options.path).attr('method', 'post').attr('style', 'display: none');

            // copy the checked checkboxes to it, along with the select_all field
            // (we do it this way in case the main form has other stuff in it that we don't want to submit)
            form.append(this.form.find('input.batch_op:checked').clone());
            form.append(this.form.find('input[name=select_all_pages]').clone());
            const pages_field = this.form.find('input[name=pages]');
            pages_field.val(this.pages);
            form.append(pages_field.clone());
            if (this.search_form_view) {
                form.append(this.search_form_view.$el.find('input[name=search]').clone());
            }

            const token = $('meta[name="csrf-token"]').attr('content');
            $('<input>').attr({ type: 'hidden', name: 'authenticity_token', value: token }).appendTo(form);

            form.appendTo($('body'));
            form.submit();
        };

        if (options.turboMethod) {
            tc.confirmWithContent({
                "#confirm-title": confirmTitle || "",
                "#confirm-body": (confirmDetails ? confirmDetails : "") + `<p>${options.turboMethod === "delete" ? i18n.t("common.deleted_unrecoverable") : ""}</p>`,
                "#confirm-accept": confirmButton
            }).then((response) => {
                response ? proceed() : $("#confirm").hide();
            });
        } else if (options.path) {
            proceed();
            $("#confirm").hide();
        } else {
            $("#confirm").hide();
            console.warn("The link does not exist in the link");
        }

        return false;
    }
}

csync.Views.BatchActionsView = BatchActionsView;
