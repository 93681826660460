// csync.Views.OptionSetForm
//
// View model for the option sets form.
class OptionSetForm {
  constructor(params) {
    this.done = false;
    this.params = params;

    // Setup OptionLevelsField view
    this.optionLevelsField = new csync.Views.OptionLevelsField({
      wrapper: $('#option-levels-wrapper'),
      modal: $('#edit-option-level'),
      optionLevels: params.optionSet.levels,
      optionsLevelsReadOnly: this.params.optionsLevelsReadOnly,
      canReorder: true,
      canRemove: this.params.canRemoveOptions,
      editLink: this.params.editLink,
      removeLink: this.params.removeLink,
    });

    // Setup OptionsField view
    this.optionsField = new csync.Views.OptionsField({
      wrapper: $('#options-wrapper'),
      modal: $('#edit-option'),
      children: params.optionSet.children,
      optionsLevelsReadOnly: this.params.optionsLevelsReadOnly,
      canReorder: this.params.canReorder,
      canRemove: this.params.canRemoveOptions,
      editLink: this.params.editLink,
      removeLink: this.params.removeLink,
    });

    // Find the allow_coordinates field
    this.allowCoordinatesField = $('.form-field[data-field-name=allow_coordinates]');

    // Event listeners
    $('.add-options a.add-option').on('click', (e) => {
      this.optionsField.add();
      e.preventDefault();
    });

    $('.option_set_option_levels a.add-link').on('click', (e) => {
      this.optionLevelsField.add();
      e.preventDefault();
    });

    $('#option_set_geographic').on('change', () => this.geographicChanged());
    this.geographicChanged();

    $('#option_set_allow_coordinates').on('change', () => this.allowCoordinatesChanged());
    this.allowCoordinatesChanged();

    $('#option_set_multilevel').on('change', () => this.multilevelChanged());
    this.multilevelChanged();

    // Events to enable/disable multilevel checkbox
    $(this.optionsField.wrapper).on('change', () => this.enableMultilevelCheckbox());
    $(this.optionLevelsField.wrapper).on('change', () => this.enableMultilevelCheckbox());
    this.enableMultilevelCheckbox();

    // Hookup form submit
    $('form.option_set_form').on('submit', (e) => {
      e.preventDefault();
      this.formSubmitted();
    });

    // Hookup leave page warning unless ajax request
    if (!this.params.modalMode) {
      window.onbeforeunload = () => {
        if (this.dirty() && !this.done) return i18n.t('option_set.leave_page_warning');
      };
    }
  }

  // Methods
  dirty() {
    return this.optionsField.params.dirty || this.optionLevelsField.list.dirty;
  }

  enableMultilevelCheckbox() {
    $('#option_set_multilevel').prop(
      'disabled',
      !(this.optionLevelsField.list.count() === 0 && this.optionsField.list.maxDepth() <= 1)
    );
  }

  geographicChanged() {
    const checked = $('#option_set_geographic').is(':checked');
    this.allowCoordinatesField.css('display', checked ? 'flex' : 'none');
    if (!checked) this.allowCoordinatesField.find('input[type=checkbox]').prop('checked', false);
  }

  allowCoordinatesChanged() {
    const checked = $('#option_set_allow_coordinates').is(':checked');
    this.optionsField.list.allowCoordinates = checked;
  }

  multilevelChanged() {
    const checked = $('#option_set_multilevel').is(':checked');
    this.optionLevelsField.show(checked);
    this.optionsField.list.allowNesting(checked);
  }

  formSubmitted() {
    this.done = true;
    this.clearErrors();
    if (this.validate()) this.submitViaAjax();
  }

  prepareData() {
    const disabled = $('form.option_set_form').find(':input:disabled').removeAttr('disabled');
    const data = $('form.option_set_form').serializeHash();
    disabled.attr('disabled', 'disabled');

    if (!this.params.optionsLevelsReadOnly) {
      data.option_set = {
        level_names: this.prepareOptionLevels(),
        children_attribs: this.prepareOptions(),
      };
    }

    this.params.optionSet.name = data['option_set[name]'];
    this.params.optionSet.multilevel = data['option_set[multilevel]'] == '1';

    return data;
  }

  prepareOptionLevels() {
    return this.optionLevelsField.list.itemTree().map(node => node.item.name_translations);
  }

  prepareOptions() {
    return this.prepareOptionTree(this.optionsField.list.itemTree());
  }

  prepareOptionTree(nodes) {
    return nodes.map(node => ({
      option_attribs: {
        name_translations: node.item.name_translations,
        value: node.item.value,
      },
      id: node.item.id,
      ...(node.item.option && node.item.option.id && { option_attribs: { id: node.item.option.id } }),
      ...(this.optionsField.list.allowCoordinates && {
        option_attribs: {
          latitude: node.item.latitude,
          longitude: node.item.longitude,
        },
      }),
      children_attribs: node.children && node.children.length ? this.prepareOptionTree(node.children) : 'NONE',
    }));
  }

  submitViaAjax() {
    const data = this.prepareData();
    if (this.params.modalMode) data.modalMode = 1;
    csync.AppInit.loading(true);

    $.ajax({
      url: $('form.option_set_form').attr('action'),
      type: 'POST',
      data,
      success: (data, status, jqxhr) => {
        if (jqxhr.getResponseHeader('Content-Type').match('application/json')) {
          if (this.params.modalMode) {
            csync.AppInit.loading(false);
            this.params.optionSet.id = data;
            $('form.option_set_form').trigger('option_set_form_submit_success', [this.params.optionSet]);
          } else {
            window.location.href = data;
          }
        } else {
          csync.AppInit.loading(false);
          $('.bootstrap-form-wrapper').replaceWith(jqxhr.responseText);
        }
      },
      error: (jqxhr, status, error) => {
        if (csync.unloading) return;
        if (jqxhr.status == 400) {
          this.addError('.option_set_name', i18n.t('activerecord.errors.models.option_set.duplicate'));
        } else if (jqxhr.status == 409) {
          this.addError(
            '.option_set_options',
            i18n.t('activerecord.errors.models.option_set.cant_delete_option_in_use')
          );
        } else {
          $('.bootstrap-form-wrapper').replaceWith('Server Error');
        }
        csync.AppInit.loading(false);
      },
    });
  }
  
  validate() {
    const self = this;
    const checks = ['name_presence'];
    if (!self.params.optionsLevelsReadOnly) {
      checks.push('option_presence', 'option_depths');
    }
    let valid = true;
  
    // Run each check even if an early one fails.
    $.each(checks, function(index, c) {
      const validationMethod = (`validate_${c}`).underscore_to_camel().underscore_to_camel();
      if (typeof self[validationMethod] !== 'function') {
        throw new TypeError(`${validationMethod} is not a function`);
      }
      if (!self[validationMethod]()) valid = false;
    });
  
    return valid;
  }  

  validateNamePresence() {
    const nameField = $('#option_set_name');
    if (nameField.length === 0) return true;
    if (!nameField.val().trim()) {
      this.addError('.option_set_name', i18n.t('activerecord.errors.messages.blank'));
      return false;
    }
    return true;
  }

  validateOptionPresence() {
    if (this.optionsField.list.length === 0) {
      this.addError('.option_set_options', i18n.t('activerecord.errors.models.option_set.at_least_one'));
      return false;
    }
    return true;
  }

  validateOptionDepths() {
    if ($('#option_set_multilevel').is(':checked')) {
      const levels = this.optionLevelsField.list.length;
      const depth = this.optionsField.list.maxDepth();
      if (levels !== depth) {
        this.addError(
          '.option_set_options',
          i18n.t('activerecord.errors.models.option_set.wrong_depth', { levels, depth })
        );
        return false;
      }
    }
    return true;
  }

  addError(selector, msg) {
    $(`${selector} .control`).prepend(
      $('<div>')
        .addClass('form-errors')
        .html(msg)
    );
  }

  clearErrors() {
    $('form.option_set_form').find('div.form-errors').remove();
  }
}

// Export the class
csync.Views.OptionSetForm = OptionSetForm;