// Popovers for hints on a form

class FormHintView extends csync.Views.ApplicationView {
  initialize() {
    this.initPopovers();
  }

  initPopovers() {
    const init = (event) => {
      const hintElements = Array.from(document.querySelectorAll('a.hint'));
      hintElements.forEach((hintElement) => {
        let popover = new bootstrap.Popover(hintElement, { html: true });
        hintElement.addEventListener('click', function() {
          // Hide all other popovers
          hintElements.forEach((el) => {
            if (el !== hintElement) {
              let popover = bootstrap.Popover.getInstance(el);
              if (popover) {
                popover.hide();
              }
            }
          });
          // Toggle the clicked popover
          popover.toggle();
        });
      });
  
      // Hide all popovers on click anywhere
      document.addEventListener('click', function(event) {
        if (!event.target.matches('a.hint')) {
          hintElements.forEach((el) => {
            let popover = bootstrap.Popover.getInstance(el);
            if (popover) {
              popover.hide();
            }
          });
        }
      }, false);
  
      // Hide all popovers on scroll
      window.addEventListener('scroll', function() {
        hintElements.forEach((el) => {
          let popover = bootstrap.Popover.getInstance(el);
          if (popover) {
            popover.hide();
          }
        });
      });
    };
  
    $(document).on('shown.bs.modal', init);
    init();
  }  
}

csync.Views.FormHintView = FormHintView;