//

csync.Views.AnswerMapView = class AnswerMapView extends csync.Views.ApplicationView {
  initialize(params) {

    $(`#${params.el}`).show();

    const style = {
      "version": 8,
      "sources": {
        "osm": {
          "type": "raster",
          "tiles": ["https://a.tile.openstreetmap.org/{z}/{x}/{y}.png"],
          "tileSize": 256,
          "attribution": "© OpenStreetMap Contributors",
          "maxzoom": 19
        }
      },
      "layers": [
        {
          "id": "osm",
          "type": "raster",
          "source": "osm"
        }
      ]
    };

    // create the map
    this.map = new maplibregl.Map({
      container: params.el,
      style: style,
      zoom: 9, // The maps are so small that we can lose context if zooming too close.
      attributionControl: false
    });

    // add a marker for each option and calculate bounds
    const bounds = new maplibregl.LngLatBounds();
    params.points.forEach((point) => {
      const latlng = [point.longitude, point.latitude];
      new maplibregl.Marker({
        color: "#FF0000", // Change the color of the marker here
      }).setLngLat(latlng).addTo(this.map);
      
      bounds.extend(latlng);
    });

    this.map.fitBounds(bounds.toArray());
  }
};