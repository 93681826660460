// csync.Views.OptionsField
//
// View model for the options area of the option sets form.

class OptionsField {
  constructor(params) {
    this.params = params;
    this.children = params.children;

    // Create the draggable list to hold the options
    this.list = new csync.Views.DraggableList({
      items: params.children || [],
      itemClass: csync.Models.OptionNode,
      wrapper: params.wrapper,
      modal: params.modal,
      optionsLevelsReadOnly: params.optionsLevelsReadOnly,
      multilevel: true,
      canReorder: this.params.canReorder,
      canRemove: this.params.canRemove,
      editLink: this.params.editLink,
      removeLink: this.params.removeLink,
      parentChangeAllowed: this.parentChangeAllowed.bind(this), // Ensure correct binding
      modalTitles: {
        new: i18n.t('option_set.new_option'),
        edit: i18n.t('option_set.edit_option'),
      },
    });
  }

  add() {
    this.list.new_item();
  }

  parentChangeAllowed(item) {
    return item.removable;
  }
}

csync.Views.OptionsField = OptionsField;
